import '../styles/global.scss';
import '../styles/pages/appstore-trial.scss';
import {textImg, mainPhone, Shadow, Phone1, Phone2, Phone3, Phone4} from "../images";

import {HeadFC} from "gatsby";

import React, {useEffect, useState} from 'react'
import AppstoreScreen from '../components/AppstoreScreen';
import AppstoreFooter from '../components/AppstoreFooter';
import TimerCTA from '../components/TimerCTA';
import useAnalytics from "../hooks/useAnalytics";

type Props = {
    id: number,
    text: string,
    image: any,
}

const AppstoreTrial = (props: Props) => {
    const {initAnalytics, saveEmail, saveSubType} = useAnalytics();

    useEffect(() => {
        initAnalytics()
    }, []);
    const [screens, setScreens] = useState([
        {
            id: 1,
            text: 'Gain professional skills via dialog-mode with artificial intelligence',
            image: Phone1,
        },
        {
            id: 2,
            text: 'Note all important actions and decisions in Business Diary',
            image: Phone2,
        },
        {
            id: 3,
            text: 'Manage your finances in AEER Finances',
            image: Phone3,
        },
        {
            id: 4,
            text: 'Set your goals and aims in AEER Target',
            image: Phone4,
        }
    ])

    return (
        <>
            <div className="MobileWrap">
                <div className="Appstore">
                    <div className="AppstoreBanner">
                        <div className="containerSpec">
                            <div className="AppstoreUpgrade">
                                <span>
                                    upgrade <br /> <span>yourself</span>
                                </span>
                                <img src={textImg} alt="" />
                            </div>
                            <div className="AppstorePhone">
                                <img src={mainPhone} alt="" />
                            </div>
                            <img src={Shadow} alt="" className="AppstoreShadow" />
                            <div className="AppstoreBott">
                                <p className="AppstoreText">EDUCATION AND PRODUCTIVITY <br /> IN ONE APP</p>
                            </div>
                        </div>
                    </div>
                    <div className="AppstoreScreens">
                        <div className="containerSpec">
                            {screens.map(key => (
                                <AppstoreScreen key={key.id} props={key} />
                            ))}
                        </div>
                    </div>

                    <AppstoreFooter />
                    <TimerCTA timerVal="3:00" link="/trial" />
                </div>
            </div>
        </>
    )
}

export default AppstoreTrial;

export const Head: HeadFC = () => (
    <>
        <title>Upgrade yourself - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);
