import './styles.scss';
import React, {memo} from 'react';
import {ceo} from "../../images";
import { Link } from 'gatsby';

const AppstoreFooter = () => {

    return (
        <>
            <div className="Ceo">
                <img src={ceo} alt="" className="CeoIcon" />
                <div className="CeoInfo">
                    <span>СЕО</span>
                    <h3>Andrew Filatov</h3>
                    <p>My goal is to create a product that will help people become more productive</p>
                </div>
            </div>
            <div className="AppstoreFooter">
                <p className="white">© AEER PLATFORM INC</p>
                <p>8 THE GREEN STE A DOVER, DE 19901</p>
                <div className="AppstoreFooterLinks">
                    <Link to="/terms">Terms of user</Link>
                    <Link to="/privacy">Privacy policy</Link>
                    <Link to="/cookie">Cookie policy</Link>
                    <Link to="/money-back">Money-Back Policy</Link>
                    <Link to="/cancel">Сancel subscription</Link>
                </div>
            </div>
            <div className="AppstoreSpace"></div>
        </>
    )
}

export default memo(AppstoreFooter);
