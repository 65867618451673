import './styles.scss';
import React, {memo} from 'react';

type Props = {
    props: {
        id: number,
        text: string,
        image: any,
    }
}

const AppstoreScreen = ({props}: Props) => {

    return (
        <div className="AppstoreScreen" >
            <p>{props.text}</p>
            <a href="/trial">
                <img src={props.image} alt="" />
            </a>
        </div>
    )
}

export default memo(AppstoreScreen);
